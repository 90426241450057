import React, { Component } from "react";
import { Radio, message, Checkbox } from "antd";
import URLs from "../../utils/apis";
import axios from "axios";
import helperfunctions from "../../utils/functions";

export default class GovermentFacilities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ration_card_types: ["NA", "White", "Orange", "Yellow", "Antodaya"],
      benefits: [
        "ST pass",
        "Railway Pass",
        "Edu. Scholarship",
        "Pension",
        "other",
      ],
      propertyTaxConsession: "",
      localWalefareFund: "",
      ration_card_type: "",
      ration_card_no: "",
      benefit_name: [],
      candidate_id: "",
    };
  }
  componentDidMount() {
    this.setState({
      candidate_id: this.props.candidate_id,
    });
  }
  submit = () => {
    if (!this.state.candidate_id) {
      message.warning("Invalid request. Please fill Personal details first.");
      return;
    }
    if (this.state.ration_card_no.includes("-")) {
      message.warning("Invalid Ration card no format.");
      return;
    }
    if (
      this.state.ration_card_no &&
      helperfunctions.containsOnlyNumbers(this.state.ration_card_no) == false
    ) {
      message.warning("Invalid Ration card no format.");
      return;
    }
    if (this.state.ration_card_no && this.state.ration_card_no.length != 10) {
      message.warning("Invalid Ration card no format.");
      return;
    }
    let params = {
      propertyTaxConsession: this.state.propertyTaxConsession,
      localWalefareFund: this.state.localWalefareFund,
      ration_card_type: this.state.ration_card_type,
      ration_card_no: this.state.ration_card_no,
      benefit_name: JSON.stringify(this.state.benefit_name),
      id: this.state.candidate_id,
      step: "4",
    };
    let token = localStorage.getItem("v_token");
    axios
      .post(URLs.add_new_candidate + "?token=" + token, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.set();
            }
          );
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        } else {
          message.warning(response.data.message);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Something went wrong.Please try again.");
      });
  };
  handleChangeTaxConsession = (event) => {
    this.setState({ propertyTaxConsession: event.target.value });
  };
  handleChangeWalefareFund = (event) => {
    this.setState({ localWalefareFund: event.target.value });
  };
  // benefit_name
  onChangeScheme = (e) => {
    let arr = this.state.benefit_name;
    if (arr.includes(e.target.value)) {
      let temparr = [];
      for (let item of arr) {
        if (item != e.target.value) {
          temparr.push(item);
        }
      }
      this.setState({
        benefit_name: temparr,
      });
    } else {
      arr.push(e.target.value);
      this.setState({
        benefit_name: arr,
      });
    }
  };
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Ration card type</label>
            <select
              className="form-control border-1"
              value={this.state.ration_card_type}
              onChange={(e) =>
                this.setState({ ration_card_type: e.target.value })
              }
            >
              <option value={""}>Select</option>
              {this.state.ration_card_types &&
                this.state.ration_card_types.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="form-group col-lg-6">
            <label className="pt-2 font-weight-medium">
              Enter ration card no
            </label>
            <input
              type="text"
              className="form-control border-1"
              value={this.state.ration_card_no}
              maxLength={10}
              onChange={(e) =>
                this.setState({ ration_card_no: e.target.value })
              }
            />
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">
              Benefit of govt. schemes
            </label>
            <div>
              <Checkbox onChange={this.onChangeScheme} value="ST pass">
                ST pass
              </Checkbox>

              <Checkbox onChange={this.onChangeScheme} value="Railway Pass">
                Railway Pass
              </Checkbox>

              <Checkbox onChange={this.onChangeScheme} value="Edu. Scholarship">
                Edu. Scholarship
              </Checkbox>

              <Checkbox onChange={this.onChangeScheme} value="Pension">
                Pension
              </Checkbox>

              <Checkbox onChange={this.onChangeScheme} value="Other">
                Other
              </Checkbox>
            </div>
          </div>
          <div className="col-lg-6"></div>
          <div className="col-lg-6">
            <div className="form-group pt-4">
              <label className="font-weight-medium">
                Property Tax Concession:{" "}
              </label>{" "}
              <br />
              <Radio.Group
                onChange={this.handleChangeTaxConsession}
                value={this.state.propertyTaxConsession}
              >
                <Radio value={"Yes"}>Yes</Radio>
                <Radio value={"No"}>No</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group pt-4">
              <label className="font-weight-medium">Local Welfare Fund: </label>
              <br />
              <Radio.Group
                onChange={this.handleChangeWalefareFund}
                value={this.state.localWalefareFund}
              >
                <Radio value={"Yes"}>Yes</Radio>
                <Radio value={"No"}>No</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
        <button
          className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
          type="button"
          style={{
            width: 130,
          }}
          onClick={() => this.submit()}
        >
          Next & Submit
        </button>
      </div>
    );
  }
}
