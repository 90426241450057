import React, { Component } from "react";
import { Checkbox, Radio, message } from "antd";
import URLs from "../../utils/apis";
import axios from "axios";
import helperfunctions from "../../utils/functions";

export default class HousingFacilities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type_of_house_list: ["Stone & soil", "Concrete", "Planting tree"],
      typeOfHouse: "",
      ownership: "",
      no_of_rooms: 0,
      toilet: "",
      door_step: "",
      electric_switch: "",
      bed_for_person: "",
      ramp_for_wheelchair: "",
      candidate_id: "",
    };
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    // Get the value of the 'id' parameter
    const cid = searchParams.get("cid");
    if (cid) {
      this.setState(
        {
          candidate_id: cid,
        },
        () => this.get_list()
      );
    } else {
      window.location.assign("/submitted-forms");
    }
  }
  get_list = () => {
    let params = {
      volunteer_id: localStorage.getItem("volunteer_id"),
    };
    let token = localStorage.getItem("v_token");
    axios
      .post(URLs.get_candidate_list + "?token=" + token, params)
      .then((response) => {
        if (response.data.status == "1") {
          for (let item of response.data.data) {
            if (item.candidate_id == this.state.candidate_id) {
              this.setState({
                typeOfHouse: item.type_of_house,
                ownership: item.ownership,
                no_of_rooms: item.no_of_rooms,
                toilet: item.toilet,
                door_step: item.door_step,
                electric_switch: item.electric_switch,
                bed_for_person: item.bed_for_persion,
                ramp_for_wheelchair: item.ramp_for_wheelchair,
              });
              return;
            }
          }
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  submit = () => {
    if (!this.state.candidate_id) {
      message.warning("Invalid request. Please try again.");
      return;
    }
    if (
      this.state.no_of_rooms &&
      helperfunctions.containsOnlyNumbers(this.state.no_of_rooms) == false
    ) {
      message.warning("Invalid No of Rooms value");
      return;
    }
    let params = {
      typeOfHouse: this.state.typeOfHouse,
      ownership: this.state.ownership,
      no_of_rooms: this.state.no_of_rooms,
      toilet: this.state.toilet,
      door_step: this.state.door_step,
      electric_switch: this.state.electric_switch,
      bed_for_person: this.state.bed_for_person,
      ramp_for_wheelchair: this.state.ramp_for_wheelchair,
      id: this.state.candidate_id,
      step: "5",
    };
    let token = localStorage.getItem("v_token");
    axios
      .post(URLs.update_candidate_details + "?token=" + token, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.set();
            }
          );
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        } else {
          message.warning(response.data.message);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Something went wrong.Please try again.");
      });
  };
  handleChangeRadioButton = (event) => {
    console.log("event", event);
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Type of House</label>
            <select
              className="form-control border-1"
              value={this.state.typeOfHouse}
              style={{ color: "#000" }}
              onChange={(e) => this.setState({ typeOfHouse: e.target.value })}
            >
              <option value={""}>Select</option>
              {this.state.type_of_house_list &&
                this.state.type_of_house_list.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Ownership</label>
            <select
              className="form-control border-1"
              value={this.state.ownership}
              style={{ color: "#000" }}
              onChange={(e) => this.setState({ ownership: e.target.value })}
            >
              <option value={""}>Select</option>
              <option value={"own"}>Own</option>
              <option value={"rented"}>Rented</option>
            </select>
          </div>
          <div className="form-group col-lg-6">
            <label className="pt-2 font-weight-medium">Number of Rooms</label>
            <input
              type="text"
              className="form-control border-1"
              value={this.state.no_of_rooms}
              onChange={(e) => this.setState({ no_of_rooms: e.target.value })}
            />
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Toilet</label>
            <select
              className="form-control border-1"
              value={this.state.toilet}
              style={{ color: "#000" }}
              onChange={(e) => this.setState({ toilet: e.target.value })}
            >
              <option value={""}>Select</option>
              <option value={"Indian inside"}>Indian inside</option>
              <option value={"Indian Outside"}>Indian Outside</option>
              <option value={"western Inside"}>western Inside</option>
              <option value={"Western outside"}>Western outside</option>
            </select>
          </div>
          <div className="col-lg-6">
            <label className=" font-weight-medium">Door Step</label>
            <br />
            <Radio.Group
              onChange={this.handleChangeRadioButton}
              value={this.state.door_step}
              name="door_step"
            >
              <Radio value={"Yes"}>Yes</Radio>
              <Radio value={"No"}>No</Radio>
            </Radio.Group>
          </div>
          <div className="col-lg-6">
            <label className=" font-weight-medium">
              Electric Switch at hand
            </label>
            <br />
            <Radio.Group
              onChange={this.handleChangeRadioButton}
              value={this.state.electric_switch}
              name="electric_switch"
            >
              <Radio value={"Yes"}>Yes</Radio>
              <Radio value={"No"}>No</Radio>
            </Radio.Group>
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Bed for Person</label>
            <br />
            <Radio.Group
              onChange={this.handleChangeRadioButton}
              value={this.state.bed_for_person}
              name="bed_for_person"
            >
              <Radio value={"Yes"}>Yes</Radio>
              <Radio value={"No"}>No</Radio>
            </Radio.Group>
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">
              Ramp for wheelchair
            </label>
            <br />
            <Radio.Group
              onChange={this.handleChangeRadioButton}
              value={this.state.ramp_for_wheelchair}
              name="ramp_for_wheelchair"
            >
              <Radio value={"Yes"}>Yes</Radio>
              <Radio value={"No"}>No</Radio>
              <Radio value={"Not Required"}>Not Required</Radio>
            </Radio.Group>
          </div>
        </div>
        <button
          className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
          type="button"
          style={{
            width: 130,
          }}
          onClick={() => this.submit()}
        >
          Update Details
        </button>
      </div>
    );
  }
}
