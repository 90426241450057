import React, { Component } from "react";
import { Checkbox, Radio, message, Spin } from "antd";
import URLs from "../../utils/apis";
import axios from "axios";

export default class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adhar_card_front: "",
      adhar_card_back: "",
      ration_card: "",
      pan_card: "",
      disability_certificate: "",
      disability_card: "",
      educational_documents: "",
      income_certificate: "",
      candidate_id: "",
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({
      candidate_id: this.props.candidate_id,
    });
  }
  submit = () => {
    if (!this.state.candidate_id) {
      message.warning("Invalid request. Please fill Personal details first.");
      return;
    }
    let formdata = new FormData();
    formdata.append("adhar_card_front", this.state.adhar_card_front);
    formdata.append("adhar_card_back", this.state.adhar_card_back);
    formdata.append("ration_card", this.state.ration_card);
    formdata.append("pan_card", this.state.pan_card);
    formdata.append(
      "disability_certificate",
      this.state.disability_certificate
    );
    formdata.append("disability_card", this.state.disability_card);
    formdata.append("educational_documents", this.state.educational_documents);
    formdata.append("income_certificate", this.state.income_certificate);
    formdata.append("id", this.state.candidate_id);
    formdata.append("step", "7");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        // "Content-Type": "application/json",
      },
    };
    let token = localStorage.getItem("v_token");
    this.setState({ loading: true });
    axios
      .post(URLs.add_new_candidate + "?token=" + token, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.set();
            }
          );
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          this.setState({ loading: false });
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        } else {
          message.warning(response.data.message);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Something went wrong.Please try again.");
      });
  };
  render() {
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <div className="row">
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">Adhar Card (Front)</label>
              <input
                type="file"
                className="form-control border-1"
                onChange={(e) =>
                  this.setState({ adhar_card_front: e.target.files[0] })
                }
              />
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">Adhar Card (Back)</label>
              <input
                type="file"
                className="form-control border-1"
                onChange={(e) =>
                  this.setState({ adhar_card_back: e.target.files[0] })
                }
              />
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">Ration Card</label>
              <input
                type="file"
                className="form-control border-1"
                onChange={(e) =>
                  this.setState({ ration_card: e.target.files[0] })
                }
              />
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">Pan Card</label>
              <input
                type="file"
                className="form-control border-1"
                onChange={(e) => this.setState({ pan_card: e.target.files[0] })}
              />
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">
                Disability Certificate
              </label>
              <input
                type="file"
                className="form-control border-1"
                onChange={(e) =>
                  this.setState({ disability_certificate: e.target.files[0] })
                }
              />
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">Disability Card</label>
              <input
                type="file"
                className="form-control border-1"
                onChange={(e) =>
                  this.setState({ disability_card: e.target.files[0] })
                }
              />
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">Income certificate</label>
              <input
                type="file"
                className="form-control border-1"
                onChange={(e) =>
                  this.setState({ income_certificate: e.target.files[0] })
                }
              />
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">
                Educational Documents
              </label>
              <input
                type="file"
                className="form-control border-1"
                multiple={true}
                onChange={(e) =>
                  this.setState({ educational_documents: e.target.files[0] })
                }
              />
            </div>
          </div>
          <button
            className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
            type="button"
            style={{
              width: 130,
            }}
            onClick={() => this.submit()}
          >
            Next & Submit
          </button>
        </Spin>
      </div>
    );
  }
}
