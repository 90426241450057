import React, { Component } from "react";
import { message, Spin } from "antd";
import URLs from "./utils/apis";
import axios from "axios";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      password: "",
      otp: "",
      loading: false,
      isOtpSent: false,
      otpValue: "",
      type: "password",
    };
  }
  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const value = urlParams.get("type");
    console.log("type = ", value);
  }
  signin = () => {
    if (!this.state.mobile) {
      message.warning("Mobile No / Email Address is required.");
      return;
    }
    if (!this.state.password) {
      message.warning("Password is required.");
      return;
    }
    let params = {
      mobile: this.state.mobile,
      password: this.state.password,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.verifyotp, params, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*", // Set the appropriate origin or '*' for any origin
        },
      })
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          localStorage.setItem(
            "volunteer_id",
            response.data.data[0].volunteer_id
          );
          localStorage.setItem("volunteer_name", response.data.data[0].name);
          localStorage.setItem("v_token", response.data.token);
          localStorage.setItem("is_mobile", "1");
          this.setState({ loading: false });
          window.location.assign("/dashboard");
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  togglePasswordType = () => {
    let type = this.state.type == "password" ? "text" : "password";
    this.setState({ type });
  };
  render() {
    return (
      <div className="login-page">
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
              <div className="row flex-grow">
                <div className="col-lg-6 d-flex align-items-center justify-content-center m-auto">
                  <div className="auth-form-transparent text-left p-3">
                    <div className="brand-logo">
                      <img
                        src="./assets/images/sahaas-logo.png"
                        alt="logo"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "auto",
                          maxWidth: 95,
                        }}
                      />
                    </div>
                    <h4 className="text-center">Volunteer Sign-In</h4>
                    <h6 className="text-center" style={{ fontWeight: "400" }}>
                      Happy to see you again!
                    </h6>
                    <Spin spinning={this.state.loading}>
                      <form className="pt-3">
                        <div className="form-group">
                          <label for="exampleInputEmail">
                            Mobile Number / Email ID
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend bg-transparent">
                              <span className="input-group-text bg-transparent border-right-0">
                                <box-icon
                                  type="solid"
                                  name="user"
                                  size="17px"
                                  color="#6201ed"
                                  style={{
                                    position: "relative",
                                    left: 6,
                                  }}
                                ></box-icon>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-lg border-left-0"
                              id="exampleInputEmail"
                              placeholder="Mobile Number / Email ID"
                              value={this.state.mobile}
                              onChange={(e) => {
                                this.setState({ mobile: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label for="exampleInputEmail">Password</label>
                          <div className="input-group">
                            <div className="input-group-prepend bg-transparent">
                              <span className="input-group-text bg-transparent border-right-0">
                                <box-icon
                                  type="solid"
                                  name="user"
                                  size="17px"
                                  color="#6201ed"
                                  style={{
                                    position: "relative",
                                    left: 6,
                                  }}
                                ></box-icon>
                              </span>
                            </div>
                            <div
                              className="form-control form-control-lg"
                              style={{ position: "relative", borderLeft: 0 }}
                            >
                              <input
                                type={this.state.type}
                                className="border-left-0"
                                id="exampleInputEmail"
                                placeholder="Password"
                                style={{
                                  border: "none",
                                  outline: "none",
                                }}
                                value={this.state.password}
                                onChange={(e) => {
                                  this.setState({ password: e.target.value });
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  right: 15,
                                  top: 15,
                                  cursor: "pointer",
                                }}
                                onClick={() => this.togglePasswordType()}
                              >
                                {this.state.type == "password" ? (
                                  <box-icon name="show" color="grey"></box-icon>
                                ) : (
                                  <box-icon
                                    type="solid"
                                    name="low-vision"
                                    color="grey"
                                  ></box-icon>
                                )}
                              </div>
                            </div>
                          </div>
                          <a
                            style={{
                              marginTop: 20,
                              marginBottom: 15,
                              float: "right",
                              color: "#6201ed",
                              fontWeight: "600",
                            }}
                            href="/forgot-password"
                          >
                            Forgot Password
                          </a>
                        </div>
                        <div className="my-3">
                          <button
                            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                            type="button"
                            onClick={() => this.signin()}
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                    </Spin>
                    <p className="text-center pt-2">
                      Don't have account?{" "}
                      <a
                        href="/register"
                        style={{ color: "#6201ed", fontWeight: "600" }}
                      >
                        Register
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- content-wrapper ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    );
  }
}
