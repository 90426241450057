import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import { Collapse } from "antd";
import AddressDetails from "./compenents/editCandidate/AddressDetails";
import DisablilityDetails from "./compenents/editCandidate/DisablilityDetails";
import Documents from "./compenents/editCandidate/Documents";
import GovermentFacilities from "./compenents/editCandidate/GovermentFacilities";
import Help from "./compenents/editCandidate/Help";
import HousingFacilities from "./compenents/editCandidate/HousingFacilities";
import PersonalDetails from "./compenents/editCandidate/PersonalDetails";

import { message } from "antd";
import URLs from "./utils/apis";
import axios from "axios";

export default class AddCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activekey: "1",
      records: [],
      candidate_id: "",
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      step5: false,
      step6: false,
      step7: false,
      photo_gallery_url: "",
    };
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    // Get the value of the 'id' parameter
    const cid = searchParams.get("cid");
    if (cid) {
      this.get_candidate_profile_details(cid);
    } else {
      window.location.assign("/submitted-forms");
    }
  }
  get_candidate_profile_details = (cid) => {
    let params = {
      volunteer_id: localStorage.getItem("volunteer_id"),
    };
    let token = localStorage.getItem("v_token");
    axios
      .post(URLs.get_candidate_list + "?token=" + token, params)
      .then((response) => {
        if (response.data.status == "1") {
          for (let item of response.data.data) {
            if (item.candidate_id == cid) {
              let photo_gallery_url = item.photo_gallery
                ? URLs.BASE_URL + item.photo_gallery
                : "";
              console.log("photo_gallery_url", photo_gallery_url);
              this.setState({
                photo_gallery_url: photo_gallery_url,
              });
              return;
            }
          }
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  setCandidateid = (id) => {
    console.log("id", id);
    // this.setState({ candidate_id: id,step1:true,activekey:"2" });
  };
  setKey2 = () => {
    // this.setState({ step2:true,activekey:"3" });
  };
  setKey3 = () => {
    // this.setState({ step3:true,activekey:"4" });
  };
  setKey4 = () => {
    // this.setState({ step4:true,activekey:"5" });
  };
  setKey5 = () => {
    // this.setState({ step5:true,activekey:"6" });
  };
  setKey6 = () => {
    // this.setState({ step6:true,activekey:"7" });
  };
  setKey7 = () => {
    // this.setState({
    //   step5:true,activekey:"7"
    // },()=>{
    //   setTimeout(()=>{
    //     window.location.assign('submitted-forms')
    //   },5000)
    // });
  };
  onChange = (key) => {
    console.log(key);
    this.setState({
      activekey: key,
    });
  };
  render() {
    const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
    const items = [
      {
        key: "1",
        label: "Personal Details",
        collapsible: this.state.step1 == true ? "disabled" : "",
        children: (
          <PersonalDetails
            set={this.setCandidateid}
            candidate_id={this.state.candidate_id}
          />
        ),
      },
      {
        key: "2",
        collapsible: this.state.step2 == true ? "disabled" : "",
        label: "Address & Emergency Contact",
        children: (
          <AddressDetails
            set={this.setKey2}
            candidate_id={this.state.candidate_id}
          />
        ),
      },
      {
        key: "3",
        collapsible: this.state.step3 == true ? "disabled" : "",
        label: "Disability Details",
        children: (
          <DisablilityDetails
            set={this.setKey3}
            candidate_id={this.state.candidate_id}
          />
        ),
      },
      {
        key: "4",
        collapsible: this.state.step4 == true ? "disabled" : "",
        label: "Government Facilities",
        children: (
          <GovermentFacilities
            set={this.setKey4}
            candidate_id={this.state.candidate_id}
          />
        ),
      },
      {
        key: "5",
        collapsible: this.state.step5 == true ? "disabled" : "",
        label: "Housing Facilities",
        children: (
          <HousingFacilities
            set={this.setKey5}
            candidate_id={this.state.candidate_id}
          />
        ),
      },
      {
        key: "6",
        collapsible: this.state.step6 == true ? "disabled" : "",
        label: "Help",
        children: (
          <Help set={this.setKey6} candidate_id={this.state.candidate_id} />
        ),
      },
      {
        key: "7",
        collapsible: this.state.step7 == true ? "disabled" : "",
        label: "Attach Documents",
        children: (
          <Documents
            set={this.setKey7}
            candidate_id={this.state.candidate_id}
          />
        ),
      },
    ];
    return (
      <div>
        <div className="container-scroller">
          <Header />
          <div className="container-fluid page-body-wrapper">
            {/* <!-- partial:partials/_settings-panel.html --> */}
            <Sidebar />
            {/* <!-- partial --> */}
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="card">
                  <div
                    className="card-body"
                    style={{
                      overflowX: "hidden",
                    }}
                  >
                    <h4
                      className="card-title"
                      style={{
                        fontSize: 24,
                        paddingBottom: 30,
                        paddingTop: 15,
                      }}
                    >
                      Update Candidate Details
                    </h4>
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="add-candidate-form">
                                {this.state.photo_gallery_url && (
                                  <div>
                                    <img
                                      src={this.state.photo_gallery_url}
                                      style={{
                                        height: 150,
                                        width: 150,
                                        borderRadius: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "10px auto 22px auto",
                                      }}
                                    />
                                  </div>
                                )}
                                <Collapse
                                  accordion
                                  items={items}
                                  defaultActiveKey={this.state.activekey}
                                  activeKey={this.state.activekey}
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- content-w/rapper ends --> */}
              {/* <!-- partial:partials/_footer.html --> */}
              <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                  <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Copyright © 2024 . All rights reserved.
                  </span>
                </div>
              </footer>
              {/* <!-- partial --> */}
            </div>
            {/* <!-- main-panel ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    );
  }
}
