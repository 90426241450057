import React, { Component } from "react";
import { Switch, message, Spin } from "antd";
import URLs from "../../utils/apis";
import axios from "axios";
import helperfunctions from "../../utils/functions";

export default class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sahaas_id: "",
      candidate_id: "",
      religion_list: [
        "Hindus",
        "Muslims",
        "Christians",
        "Sikhs",
        "Buddhists",
        "Buddhists",
        "Jains",
        "Other religions",
      ],
      language_list: [
        "Hindi",
        "English",
        "Bengali",
        "Marathi",
        "Telugu",
        "Tamil",
        "Gujrati",
        "Urdu",
        "Kannada",
        "Odia",
        "Malayalam",
        "Punjabi",
        "Assamese",
        "Maithili",
        "Meitei",
        "Sanskrit",
      ],
      bloodGroupList: ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"],
      have_adhar: false,
      adhar_no: "",
      name: "",
      gender: "",
      dob: "",
      blood_group: "",
      marital_status: "",
      religion: "",
      caste: "",
      language: "",
      personal_contact: "",
      photo_gallery: "",
      candidate_id: "",
      loading: false,
      own_age: "",
    };
  }
  componentDidMount() {
    this.intervalId = setInterval(this.get_sahaas_id, 10000);
    this.get_sahaas_id();
  }
  componentDidUpdate() {
    // this.setState({
    //   candidate_id: this.props.candidate_id
    // })
  }
  get_sahaas_id = () => {
    let token = localStorage.getItem("v_token");
    axios
      .get(URLs.get_sahaas_id + "?token=" + token)
      .then((response) => {
        if (response.data.status == "1") {
          if (!this.state.candidate_id) {
            this.setState({
              sahaas_id: response.data.data,
            });
          }
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  handleImageChange = (e) => {
    this.setState({
      photo_gallery: e.target.files[0],
    });
  };
  validateAadhaar = (aadhaarNumber) => {
    // Regex pattern for Aadhaar number validation
    var aadhaarPattern = /^\d{4}\s\d{4}\s\d{4}$/;

    // Check if the provided number matches the pattern
    if (aadhaarPattern.test(aadhaarNumber)) {
      return true; // Valid Aadhaar number
    } else {
      return false; // Invalid Aadhaar number
    }
  };
  submit = () => {
    let token = localStorage.getItem("v_token");
    if (!this.state.sahaas_id) {
      message.warning("Sahaas id is required");
      return;
    }
    if (this.state.have_adhar == true) {
      if (!this.state.adhar_no) {
        message.warning("Adhar No is required.");
        return;
      } else if (this.validateAadhaar(this.state.adhar_no) == false) {
        message.warning("Invalid format of Aadhaar Card No.");
        return;
      }
    }
    if (!this.state.name) {
      message.warning("Name is required");
      return;
    }
    if (!this.state.gender) {
      message.warning("Gender is required");
      return;
    }
    if (!this.state.dob) {
      message.warning("Date of birth is required");
      return;
    }
    if (!this.state.blood_group) {
      message.warning("Blood Group is required");
      return;
    }
    if (!this.state.marital_status) {
      message.warning("Marital Status is required");
      return;
    }
    if (!this.state.religion) {
      message.warning("Religion is required");
      return;
    }
    if (!this.state.caste) {
      message.warning("Caste is required");
      return;
    }
    if (!this.state.language) {
      message.warning("Language is required");
      return;
    }
    if (
      helperfunctions.containsOnlyNumbers(this.state.personal_contact) == false
    ) {
      message.warning("Invalid Personal contact no format.");
      return;
    }
    if (!this.state.personal_contact) {
      message.warning("Personal contact no is required");
      return;
    }
    if (this.state.personal_contact.length != 10) {
      message.warning("Invalid Personal contact no format.");
      return;
    }
    if (!this.state.photo_gallery) {
      message.warning("Please select photo gallery");
      return;
    }
    let formdata = new FormData();
    formdata.append("sahaas_id", this.state.sahaas_id);
    formdata.append("have_adhar", this.state.have_adhar == true ? "Yes" : "No");
    formdata.append("adhar_no", this.state.adhar_no);
    formdata.append("name", this.state.name);
    formdata.append("gender", this.state.gender);
    formdata.append("dob", this.state.dob);
    formdata.append("blood_group", this.state.blood_group);
    formdata.append("marital_status", this.state.marital_status);
    formdata.append("religion", this.state.religion);
    formdata.append("caste", this.state.caste);
    formdata.append("language", this.state.language);
    formdata.append("personal_contact", this.state.personal_contact);
    formdata.append("volunteer_id", localStorage.getItem("volunteer_id"));
    formdata.append("step", "1");
    formdata.append("photo_gallery", this.state.photo_gallery);
    formdata.append("candidate_id", this.state.candidate_id);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        // "Content-Type": "application/json",
      },
    };
    this.setState({ loading: true });
    axios
      .post(URLs.add_new_candidate + "?token=" + token, formdata, config)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
              candidate_id: response.data.data,
            },
            () => {
              this.props.set(response.data.data);
            }
          );
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        } else {
          message.warning(response.data.message);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Something went wrong.Please try again.");
      });
  };

  isValidPhoneNumber = (phoneNumber) => {
    // Regular expression to match typical phone number formats
    var phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
    // Alternatively, you can use this regex for a broader set of formats:
    // var phoneRegex = /^\d{3}-\d{3}-\d{4}$|^\(\d{3}\)\s*\d{3}-\d{4}$|^\d{10}$/;

    // Check if the phone number matches the regex
    return phoneRegex.test(phoneNumber);
  };

  handleChange = (checked) => {
    this.setState(
      {
        have_adhar: checked,
      },
      () => {
        if (checked == false) {
          this.setState({ adhar_no: "" });
        }
      }
    );
  };
  validateAdharNo = (e) => {
    var value = e.target.value;
    value = value
      .replace(/\D/g, "")
      .split(/(?:([\d]{4}))/g)
      .filter((s) => s.length > 0)
      .join(" ");
    this.setState({
      adhar_no: value,
    });
  };
  calculateAge = (birthDate) => {
    var currentDate = new Date();
    var birthDate = new Date(birthDate);

    var age = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust age if the current date is before the birthdate in the same year
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    this.setState({
      own_age: age,
    });
    console.log("age", age);
    return age;
  };
  render() {
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <div className="row">
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">
                Sahaas ID <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                value={this.state.sahaas_id}
                disabled={true}
              />
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className="font-weight-medium">Have aadhar:</label>{" "}
                &nbsp;&nbsp;&nbsp;
                <Switch
                  onChange={this.handleChange}
                  checked={this.state.have_adhar}
                />
              </div>
            </div>
            {this.state.have_adhar == true ? (
              <div className="col-lg-6">
                <div>
                  <label className="font-weight-medium">Adhar Card No</label>
                  <input
                    type="text"
                    className="form-control border-1 mb-3"
                    value={this.state.adhar_no}
                    data-type="adhaar-number"
                    maxLength="19"
                    onChange={this.validateAdharNo}
                  />
                </div>
              </div>
            ) : (
              <div className="form-group col-lg-6"></div>
            )}
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">
                Full Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control border-1"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">
                Gender <span className="text-danger">*</span>
              </label>
              <select
                className="form-control border-1"
                value={this.state.gender}
                onChange={(e) => this.setState({ gender: e.target.value })}
              >
                <option value={""}>Select</option>
                <option value={"Male"}>Male</option>
                <option value={"Female"}>Female</option>
              </select>
            </div>
            <div className="form-group col-lg-6">
              <label className="d-flex justify-content-between font-weight-medium">
                <span>
                  Date of Birth <span className="text-danger">*</span>
                </span>
                <span>Age: {this.state.own_age} Years</span>
              </label>
              <input
                type="date"
                className="form-control border-1"
                value={this.state.dob}
                onChange={(e) =>
                  this.setState({ dob: e.target.value }, () =>
                    this.calculateAge(e.target.value)
                  )
                }
              />
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">
                Blood Group <span className="text-danger">*</span>
              </label>
              <select
                className="form-control border-1"
                value={this.state.blood_group}
                onChange={(e) => this.setState({ blood_group: e.target.value })}
              >
                <option value={""}>Select</option>
                {this.state.bloodGroupList &&
                  this.state.bloodGroupList.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">
                Marital Status <span className="text-danger">*</span>
              </label>
              <select
                className="form-control border-1"
                value={this.state.marital_status}
                onChange={(e) =>
                  this.setState({ marital_status: e.target.value })
                }
              >
                <option value={""}>Select</option>
                <option value={"Married"}>Married</option>
                <option value={"Unmarried"}>Unmarried</option>
              </select>
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">
                Religion <span className="text-danger">*</span>
              </label>
              <select
                className="form-control border-1"
                value={this.state.religion}
                onChange={(e) => this.setState({ religion: e.target.value })}
              >
                <option value={""}>Select</option>
                {this.state.religion_list &&
                  this.state.religion_list.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">
                Caste <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control border-1"
                value={this.state.caste}
                onChange={(e) => this.setState({ caste: e.target.value })}
              />
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">
                Language <span className="text-danger">*</span>
              </label>
              <select
                className="form-control border-1"
                value={this.state.language}
                onChange={(e) => this.setState({ language: e.target.value })}
              >
                <option value={""}>Select</option>
                {this.state.language_list &&
                  this.state.language_list.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">
                Personal Contact No <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control border-1"
                value={this.state.personal_contact}
                onChange={(e) => {
                  let value = e.target.value.slice(0, 10);
                  this.setState({ personal_contact: value });
                }}
              />
            </div>
            <div className="form-group col-lg-6">
              <label className="font-weight-medium">
                Photo Gallery <span className="text-danger">*</span>
              </label>
              <input
                type="file"
                multiple={true}
                accept="image/*"
                className="form-control border-1"
                onChange={this.handleImageChange}
              />
            </div>
          </div>
          <button
            className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
            type="button"
            style={{
              width: 130,
            }}
            onClick={() => this.submit()}
          >
            Next & Submit
          </button>
        </Spin>
      </div>
    );
  }
}
