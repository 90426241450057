import React, { Component } from "react";
import URLs from "../utils/apis";
import axios from "axios";
import { message } from "antd";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
    };
  }
  componentDidMount() {
    this.get_data();
  }
  get_data = () => {
    let params = {
      volunteer_id: localStorage.getItem("volunteer_id"),
    };
    let token = localStorage.getItem("v_token");
    axios
      .post(URLs.get_candidate_list + "?token=" + token, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        }
      })
      .catch((error) => {});
  };
  render() {
    const countStyle = {
      background: "#6201ed",
      borderRadius: "50%",
      color: "#fff",
      padding: "3px 6px 3px",
      fontSize: 13,
    };
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item">
            <a className="nav-link" href="/dashboard">
              <box-icon
                type="solid"
                name="check-shield"
                color="#6201ed"
                className="menu-icon"
                size="18px"
              ></box-icon>
              <span className="menu-title">Dashboard</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/submitted-forms">
              <box-icon
                type="solid"
                name="user-circle"
                color="#6201ed"
                size="18px"
                className="menu-icon"
              ></box-icon>
              <span className="menu-title">
                Submitted Forms{" "}
                <span style={countStyle}>{this.state.records.length}</span>
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/settings">
              <box-icon
                type="solid"
                name="cog"
                color="#6201ed"
                size="18px"
                className="menu-icon"
              ></box-icon>
              <span className="menu-title">Settings</span>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}
