import React, { Component } from "react";
import { message, Spin } from "antd";
import URLs from "./utils/apis";
import axios from "axios";

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      mobile: "",
      password: "",
      confirm_password: "",
      otp: "",
      loading: false,
      isOtpSent: false,
      otpValue: "",
      otpVerified: false,
      isEmailOtpSend: false,
      emailOtpValue: "",
      isEmailOtpVerified: false,
      isEmailVerified: false,
      emailOtpValue: "",
      district_list: [],
      district_id: "",
    };
  }
  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const value = urlParams.get("type");
    this.get_district_list();
  }
  get_district_list = () => {
    axios.get(URLs.get_district_list2).then((response) => {
      if (response.data.status == "1") {
        this.setState({ district_list: response.data.data });
      } else {
      }
    });
  };
  verifyotp = () => {
    if (!this.state.mobile) {
      message.warning("Mobile No is required.");
      return;
    }
    if (!this.state.otpValue) {
      message.warning("Otp is required.");
      return;
    }
    let params = {
      mobile: this.state.mobile,
      otp: this.state.otpValue,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.verifyotpToRegister, params, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*", // Set the appropriate origin or '*' for any origin
        },
      })
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({
            loading: false,
            otpVerified: true,
          });
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  isValidEmail = (email) => {
    // Regular expression for basic email validation
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  register = () => {
    if (!this.state.name) {
      message.warning("Name is required.");
      return;
    }
    if (!this.state.email) {
      message.warning("Mobile No is required.");
      return;
    }
    if (this.isValidEmail(this.state.email) == false) {
      message.warning("Invalid format of email address.");
      return;
    }
    if (this.state.isEmailVerified != true) {
      message.warning("Please verify Email address first");
      return;
    }
    if (!this.state.mobile) {
      message.warning("Mobile No is required.");
      return;
    }
    if (this.state.mobile.length != 10) {
      message.warning("Invalid Mobile Number.");
      return;
    }
    if (this.state.otpVerified != true) {
      message.warning("Please verify Mobile no.");
      return;
    }
    if (!this.state.district_id) {
      message.warning("Please select district");
      return;
    }
    if (!this.state.password) {
      message.warning("Password is required.");
      return;
    }
    if (!this.state.confirm_password) {
      message.warning("Confirm Password is required.");
      return;
    }
    if (this.state.password != this.state.confirm_password) {
      message.warning("Both Password and confirm passowrd is not matching.");
      return;
    }
    let params = {
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
      password: this.state.password,
      confirm_password: this.state.confirm_password,
      district_id: this.state.district_id,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.register, params, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*", // Set the appropriate origin or '*' for any origin
        },
      })
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({ loading: false });
          setTimeout(() => {
            window.location.assign("/");
          }, 3000);
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  sendOtp = () => {
    if (!this.state.mobile) {
      message.warning("Mobile no is required.");
      return;
    }
    if (this.state.mobile.length != 10) {
      message.warning("Invalid format of Mobile no");
      return;
    }
    let params = {
      mobile: this.state.mobile,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.sentotpToRegister, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({
            loading: false,
            isOtpSent: true,
            otpValue: response.data.data,
          });
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  // send email otp
  sendEmailOtp = () => {
    if (!this.state.name) {
      message.warning("Name is required.");
      return;
    }
    if (!this.state.email) {
      message.warning("Email address is required.");
      return;
    }
    if (this.isValidEmail(this.state.email) == false) {
      message.warning("Invalid format of email address.");
      return;
    }
    let params = {
      email: this.state.email,
      name: this.state.name,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.send_email_otp_register, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({
            loading: false,
            isEmailOtpSend: true,
            otpValue: response.data.data,
          });
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  // verify email otp =
  verfitEmailOTP = () => {
    // verfitEmailOTP
    if (!this.state.email) {
      message.warning("Email address is required.");
      return;
    }
    if (!this.state.emailOtpValue) {
      message.warning("Email Otp is required.");
      return;
    }
    let params = {
      email: this.state.email,
      otp: this.state.emailOtpValue,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.verify_email_otp_register, params, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*", // Set the appropriate origin or '*' for any origin
        },
      })
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({
            isEmailVerified: true,
            loading: false,
          });
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  render() {
    return (
      <div className="login-page">
        <div className="container-scroller">
          <Spin spinning={this.state.loading}>
            <div className="container-fluid page-body-wrapper full-page-wrapper">
              <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                <div className="row flex-grow">
                  <div className="col-lg-6 d-flex align-items-center justify-content-center m-auto">
                    <div className="auth-form-transparent text-left p-3">
                      <div className="brand-logo">
                        <img
                          src="./assets/images/sahaas-logo.png"
                          alt="logo"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "auto",
                            maxWidth: 95,
                          }}
                        />
                      </div>
                      <h4 className="text-center">Volunteer Sign Up</h4>
                      <form className="pt-3">
                        <div className="form-group">
                          <label for="exampleInputEmail">
                            Name <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend bg-transparent">
                              <span className="input-group-text bg-transparent border-right-0">
                                <box-icon
                                  type="solid"
                                  name="user"
                                  size="17px"
                                  color="#6201ed"
                                  style={{
                                    position: "relative",
                                    left: 6,
                                  }}
                                ></box-icon>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-lg border-left-0"
                              id="exampleInputEmail"
                              placeholder="Name"
                              value={this.state.name}
                              onChange={(e) =>
                                this.setState({ name: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label for="exampleInputEmail">
                            Email Address <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend bg-transparent">
                              <span className="input-group-text bg-transparent border-right-0">
                                <box-icon
                                  type="solid"
                                  name="user"
                                  size="17px"
                                  color="#6201ed"
                                  style={{
                                    position: "relative",
                                    left: 6,
                                  }}
                                ></box-icon>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-lg border-left-0"
                              id="exampleInputEmail"
                              placeholder="Email Address"
                              value={this.state.email}
                              disabled={
                                this.state.isEmailVerified == true
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </div>
                          {this.state.isEmailOtpSend == false && (
                            <button
                              className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
                              type="button"
                              style={{
                                width: 104,
                              }}
                              onClick={() => this.sendEmailOtp()}
                            >
                              Send OTP
                            </button>
                          )}
                        </div>
                        {this.state.isEmailOtpSend == true && (
                          <div className="form-group">
                            <label for="exampleInputPassword">Email OTP</label>
                            <div className="input-group">
                              <div className="input-group-prepend bg-transparent">
                                <span className="input-group-text bg-transparent border-right-0">
                                  <box-icon
                                    size="17px"
                                    name="lock-alt"
                                    color="#6201ed"
                                    style={{
                                      position: "relative",
                                      left: 6,
                                    }}
                                  ></box-icon>
                                </span>
                              </div>
                              <input
                                type="number"
                                className="form-control form-control-lg border-left-0"
                                id="exampleInputPassword"
                                placeholder="OTP"
                                value={this.state.emailOtpValue}
                                onChange={(e) => {
                                  let value = e.target.value.slice(0, 6);
                                  this.setState({ emailOtpValue: value });
                                }}
                                disabled={
                                  this.state.isEmailVerified == true
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            {this.state.isEmailVerified == false && (
                              <button
                                className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
                                type="button"
                                style={{
                                  width: 104,
                                }}
                                onClick={() => this.verfitEmailOTP()}
                              >
                                Verify
                              </button>
                            )}
                          </div>
                        )}
                        <div className="form-group">
                          <label for="exampleInputEmail">
                            Mobile Number <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend bg-transparent">
                              <span className="input-group-text bg-transparent border-right-0">
                                <box-icon
                                  type="solid"
                                  name="user"
                                  size="17px"
                                  color="#6201ed"
                                  style={{
                                    position: "relative",
                                    left: 6,
                                  }}
                                ></box-icon>
                              </span>
                            </div>
                            <input
                              type="number"
                              className="form-control form-control-lg border-left-0"
                              id="exampleInputEmail"
                              placeholder="Mobile Number"
                              disabled={
                                this.state.otpVerified == true ? true : false
                              }
                              value={this.state.mobile}
                              onChange={(e) => {
                                let value = e.target.value.slice(0, 10);
                                this.setState({ mobile: value });
                              }}
                            />
                          </div>
                          {this.state.isOtpSent == false && (
                            <button
                              className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
                              type="button"
                              style={{
                                width: 104,
                              }}
                              onClick={() => this.sendOtp()}
                            >
                              Send OTP
                            </button>
                          )}
                        </div>
                        {this.state.isOtpSent == true && (
                          <div className="form-group">
                            <label for="exampleInputPassword">OTP</label>
                            <div className="input-group">
                              <div className="input-group-prepend bg-transparent">
                                <span className="input-group-text bg-transparent border-right-0">
                                  <box-icon
                                    size="17px"
                                    name="lock-alt"
                                    color="#6201ed"
                                    style={{
                                      position: "relative",
                                      left: 6,
                                    }}
                                  ></box-icon>
                                </span>
                              </div>
                              <input
                                type="number"
                                className="form-control form-control-lg border-left-0"
                                id="exampleInputPassword"
                                placeholder="Password"
                                value={this.state.otpValue}
                                onChange={(e) => {
                                  let value = e.target.value.slice(0, 6);
                                  this.setState({ otpValue: value });
                                }}
                                disabled={
                                  this.state.otpVerified == true ? true : false
                                }
                              />
                            </div>
                            {this.state.isOtpSent == true &&
                              this.state.otpVerified == false && (
                                <button
                                  className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
                                  type="button"
                                  style={{
                                    width: 104,
                                  }}
                                  onClick={() => this.verifyotp()}
                                >
                                  Verify
                                </button>
                              )}
                          </div>
                        )}
                        <div className="form-group">
                          <label for="exampleInputEmail">
                            Associated to District{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <select
                              value={this.state.district_id}
                              onChange={(e) =>
                                this.setState({ district_id: e.target.value })
                              }
                              className="form-control form-control-lg border-left-0"
                              style={{
                                borderLeft: 0,
                              }}
                            >
                              <option value="">Select</option>
                              {this.state.district_list &&
                                this.state.district_list.map((item, index) => {
                                  return (
                                    <option
                                      value={item.district_id}
                                      key={index}
                                    >
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label for="exampleInputEmail">
                            Password <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend bg-transparent">
                              <span className="input-group-text bg-transparent border-right-0">
                                <box-icon
                                  type="solid"
                                  name="user"
                                  size="17px"
                                  color="#6201ed"
                                  style={{
                                    position: "relative",
                                    left: 6,
                                  }}
                                ></box-icon>
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control form-control-lg border-left-0"
                              id="exampleInputEmail"
                              placeholder="Password"
                              value={this.state.password}
                              onChange={(e) =>
                                this.setState({ password: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label for="exampleInputEmail">
                            Confirm Password{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend bg-transparent">
                              <span className="input-group-text bg-transparent border-right-0">
                                <box-icon
                                  type="solid"
                                  name="user"
                                  size="17px"
                                  color="#6201ed"
                                  style={{
                                    position: "relative",
                                    left: 6,
                                  }}
                                ></box-icon>
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control form-control-lg border-left-0"
                              id="exampleInputEmail"
                              placeholder="Confirm Password"
                              value={this.state.confirm_password}
                              onChange={(e) =>
                                this.setState({
                                  confirm_password: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="my-3">
                          <button
                            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                            type="button"
                            onClick={() => this.register()}
                          >
                            Sign Up
                          </button>
                        </div>
                      </form>
                      <p className="text-center pt-2">
                        Already have account? <a href="/">Login Here</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- content-wrapper ends --> */}
            </div>
          </Spin>
          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    );
  }
}
