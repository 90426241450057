import React, { Component } from "react";
import { Checkbox, Radio, message, Slider } from "antd";
import URLs from "../../utils/apis";
import axios from "axios";

export default class DisablilityDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_list: [
        // "Paraplegic",
        // "Locomotor",
        // "Deaf & Dumb",
        // "Dwarfism",
        // "Hearing Impairment",
        // "Paralysis",
        // "Blind",
        // "Low vision",
        // "Mental Retardation",
        // "Polio",
        // "Hearing impairment",
        // "Cerebral Palsy",
        // "Intellectual Disability",
        // "Acid attack victim",
        // "Autism spectrum disorder",
        // "Chronic neurological conditions",
        // "Parkinson’s disease",
        // "Haemophilia",
        // "Leprosy cured person",
        // "Muscular dystrophy",
        // "Sickle cell disease",
        // "Specific learning disabilities",
        // "Speech & Language",
        // "Thalassemia",
        // "Multiple disability",
      ],
      isCertificateOfDisability: "",
      candidate_id: "",
      byBirth: false,
      byAccident: false,
      disability: "",
      ceritifcate_no: "",
      disability_in_percentage: 0,
      describe_disability: "",
    };
  }
  componentDidMount() {
    this.setState({
      candidate_id: this.props.candidate_id,
    });
    this.get_disability_list();
  }
  get_disability_list = () => {
    let token = localStorage.getItem("v_token");
    axios
      .get(URLs.get_disability_list + "?token=" + token)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            d_list: response.data.data,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        }
      })
      .catch((error) => {});
  };
  submit = () => {
    let token = localStorage.getItem("v_token");
    if (!this.state.candidate_id) {
      message.warning("Invalid request. Please fill Personal details first.");
      return;
    }
    if (this.state.isCertificateOfDisability == "Yes") {
      if (!this.state.ceritifcate_no) {
        message.warning("Certificate no is required.");
        return;
      }
    }
    if (this.state.disability_in_percentage) {
      let per = parseFloat(this.state.disability_in_percentage);
      if (per < 0 || per > 100) {
        message.warning(
          "Invalid disability percentage. Value should between 0 to 100."
        );
        return;
      }
    }
    let params = {
      certificate_of_disability: this.state.isCertificateOfDisability,
      candidate_id: this.state.candidate_id,
      disaility_by_birth: this.state.byBirth == true ? "1" : "0",
      disaility_by_accident: this.state.byAccident == true ? "1" : "0",
      type_of_disability: this.state.disability,
      ceritificate_no: this.state.ceritifcate_no,
      disability_in_percentage: this.state.disability_in_percentage,
      describe_disability: this.state.describe_disability,
      id: this.state.candidate_id,
      step: "3",
    };
    axios
      .post(URLs.add_new_candidate + "?token=" + token, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.set();
            }
          );
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        } else {
          message.warning(response.data.message);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Something went wrong.Please try again.");
      });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  handleChangeCertificate = (event) => {
    this.setState({ isCertificateOfDisability: event.target.value });
  };
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">
              Reason of handicap
            </label>
            <br />
            <Checkbox
              onChange={this.handleChange}
              checked={this.state.byBirth}
              name="byBirth"
            >
              By Birth
            </Checkbox>
            <Checkbox
              onChange={this.handleChange}
              checked={this.state.byAccident}
              name="byAccident"
            >
              By Accident
            </Checkbox>
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">
              Type of disability
            </label>
            <select
              className="form-control border-1"
              value={this.state.disability}
              onChange={(e) => this.setState({ disability: e.target.value })}
            >
              <option value={""}>Select</option>
              {this.state.d_list &&
                this.state.d_list.map((item, index) => {
                  return (
                    <option value={item.name} key={index}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">
              Certificate of disability
            </label>
            <br />
            <Radio.Group
              onChange={this.handleChangeCertificate}
              value={this.state.isCertificateOfDisability}
            >
              <Radio value={"Yes"}>Yes</Radio>
              <Radio value={"No"}>No</Radio>
            </Radio.Group>
          </div>
          {this.state.isCertificateOfDisability == "Yes" && (
            <div className="form-group col-lg-6">
              <label className="pt-2 font-weight-medium">
                Certificate Number
              </label>
              <input
                type="number"
                className="form-control border-1"
                value={this.state.ceritifcate_no}
                onChange={(e) =>
                  this.setState({ ceritifcate_no: e.target.value })
                }
              />
            </div>
          )}
          <div className="form-group col-lg-6">
            <label className="pt-2 font-weight-medium">
              Enter Disability In Percentage(%)
            </label>
            {/* <Slider
              min={1}
              max={100}
              onChange={(e) => this.setState({ disability_in_percentage: e })}
              value={this.state.disability_in_percentage}
            /> */}
            <input
              type="number"
              className="form-control border-1"
              value={this.state.disability_in_percentage}
              onChange={(e) =>
                this.setState({ disability_in_percentage: e.target.value })
              }
              min={0}
              max={100}
            />
          </div>
          <div className="form-group col-lg-6">
            <label className="pt-2 font-weight-medium">
              Describe Disability
            </label>
            <textarea
              id="txtid"
              name="txtname"
              rows="4"
              cols="50"
              maxlength="200"
              className="form-control border-1"
              value={this.state.describe_disability}
              onChange={(e) =>
                this.setState({ describe_disability: e.target.value })
              }
            ></textarea>
          </div>
        </div>
        <button
          className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
          type="button"
          style={{
            width: 130,
          }}
          onClick={() => this.submit()}
        >
          Next & Submit
        </button>
      </div>
    );
  }
}
