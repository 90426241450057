let BASE_URL = "https://sapi.affixtech.in/api/";
let BASE_URL2 = "https://sapi.affixtech.in/";
let URLs = {
  verifyotp: BASE_URL + "volunteer/verifyotp",
  sentotp: BASE_URL + "volunteer/sendotp",
  register: BASE_URL + "volunteer/register_new_user",
  sentotpToRegister: BASE_URL + "volunteer/sentotpToRegister",
  verifyotpToRegister: BASE_URL + "volunteer/verifyotpToRegister",

  get_dashboard_data: BASE_URL + "volunteer/get-dashboard-count",
  get_admin_profile_details: BASE_URL + "volunteer/get_profile_details",
  // admin
  get_candidate_list: BASE_URL + "volunteer/candidate/list",
  add_new_candidate: BASE_URL + "volunteer/candidate/add",
  update_candidate_details: BASE_URL + "volunteer/candidate/update",
  // update_volunteer_status: BASE_URL + "volunteer/volunteer/update_status",

  update_details: BASE_URL + "volunteer/update_profile_details",
  update_password: BASE_URL + "volunteer/update_password",
  get_disability_list: BASE_URL + "volunteer/get_disability_list",
  get_district_list: BASE_URL + "volunteer/get_district_list",
  get_district_list2: BASE_URL + "volunteer/get_district_list2",

  get_taluka_list: BASE_URL + "volunteer/get_taluka_list",
  get_state_list: BASE_URL + "volunteer/get_state_list",

  get_sahaas_id: BASE_URL + "volunteer/candidate/get_sahaas_id",
  BASE_URL: BASE_URL2,
  send_forgot_otp: BASE_URL + "volunteer/forgot-password/sentotp",
  verfity_forgot_otp: BASE_URL + "volunteer/forgot-password/verfityotp",
  change_password: BASE_URL + "volunteer/forgot-password/change-password",

  send_email_otp_register: BASE_URL + "volunteer/register_email_send_otp",
  verify_email_otp_register: BASE_URL + "volunteer/register_email_verify_otp",

  send_email_otp_setting: BASE_URL + "volunteer/setting_email_send_otp",
  verify_email_otp_setting: BASE_URL + "volunteer/setting_email_verify_otp",

  send_mobile_otp_setting: BASE_URL + "volunteer/setting_mobile_send_otp",
  verify_mobile_otp_setting: BASE_URL + "volunteer/setting_mobile_verify_otp",

  generate_single_users_pdf: BASE_URL + "volunteer/generate_single_pdf",
  generate_tabular_users_pdf: BASE_URL + "volunteer/generate_tabular_pdf",
};

export default URLs;
