import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import { Table, Dropdown, Menu, message, Modal, Spin } from "antd";
import URLs from "./utils/apis";
import axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export default class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      crecords: [],
      candidates: [],
      searchText: "",
      selectedGender: "",
      selectedDisability: "",
      selectedDistrict: "",
      selectedTaluka: "",
      selectedGovFacility: "",
      d_list: [],
      district_list: [],
      taluka_list: [],

      exportModalStatus: false,
      export_type: "",
      exportLoader: false,
      exportCount: 0,
      pagination: 25,
    };
  }
  clearFilter = () => {
    this.setState({
      searchText: "",
      selectedGender: "",
      selectedDisability: "",
      selectedDistrict: "",
      selectedTaluka: "",
      selectedGovFacility: "",
      records: this.state.crecords,
    });
  };
  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const value = urlParams.get("type");
    console.log("type = ", value);
    if (value) {
      this.setState({
        type: value,
      });
    }
    this.get_data();
    this.get_disability_list();
    this.get_district_list();
  }
  get_taluka_list = () => {
    let params = {
      d_id: this.state.selectedDistrict,
    };
    let token = localStorage.getItem("v_token");
    axios
      .post(URLs.get_taluka_list + "?token=" + token, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            taluka_list: response.data.data,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        } else {
        }
      })
      .catch((error) => {});
  };
  get_district_list = () => {
    let token = localStorage.getItem("v_token");
    axios
      .get(URLs.get_district_list + "?token=" + token)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            district_list: response.data.data,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        } else {
        }
      })
      .catch((error) => {});
  };
  get_disability_list = () => {
    let token = localStorage.getItem("v_token");
    axios
      .get(URLs.get_disability_list + "?token=" + token)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            d_list: response.data.data,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        } else {
        }
      })
      .catch((error) => {});
  };
  get_data = () => {
    let params = {
      volunteer_id: localStorage.getItem("volunteer_id"),
    };
    let token = localStorage.getItem("v_token");
    axios
      .post(URLs.get_candidate_list + "?token=" + token, params)
      .then((response) => {
        console.log("candidate response", response);
        if (response.data.status == "1") {
          let tempArr = response.data.data.sort((a, b) =>
            a.type_of_disability.localeCompare(b.type_of_disability)
          );
          this.setState({
            records: tempArr,
            crecords: tempArr,
            loading: false,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  filter_data = () => {
    let list = this.state.crecords;
    let searchArr = [];
    let searchInput = this.state.searchText;
    if (searchInput) {
      for (let item of list) {
        if (
          (item.name &&
            item.name.toLowerCase().includes(searchInput.toLowerCase())) ||
          (item.adhar_no &&
            item.adhar_no.toLowerCase().includes(searchInput.toLowerCase()))
        ) {
          searchArr.push(item);
        }
      }
    } else {
      searchArr = list;
    }
    console.log("searchArr", searchArr);
    // gender
    let genderArr = [];
    if (this.state.selectedGender) {
      let arr = [];
      for (let item of searchArr) {
        if (item.gender) {
          if (item.gender.toLowerCase() == this.state.selectedGender) {
            arr.push(item);
          }
        }
      }
      genderArr = arr;
    } else {
      genderArr = searchArr;
    }
    console.log("genderArr", genderArr);
    // disability
    let disability_arr = [];
    if (this.state.selectedDisability && this.state.selectedDisability != "") {
      let arr = [];
      for (let item of genderArr) {
        if (item.type_of_disability == this.state.selectedDisability) {
          arr.push(item);
        }
      }
      disability_arr = arr;
    } else {
      disability_arr = genderArr;
    }
    console.log("disability_arr", disability_arr);
    // district
    let district_arr = [];
    if (this.state.selectedDistrict) {
      let arr = [];
      for (let item of disability_arr) {
        if (item.district_id == this.state.selectedDistrict) {
          arr.push(item);
        }
      }
      district_arr = arr;
    } else {
      district_arr = disability_arr;
    }
    // taluka
    let taluka_arr = [];
    if (this.state.selectedTaluka) {
      let arr = [];
      for (let item of district_arr) {
        if (item.taluka_id == this.state.selectedTaluka) {
          arr.push(item);
        }
      }
      taluka_arr = arr;
    } else {
      taluka_arr = district_arr;
    }

    // gov facilities
    let gov_f_arr = [];
    if (this.state.selectedGovFacility) {
      let arr = [];
      for (let item of taluka_arr) {
        let faciities = item.gov_benifits ? JSON.parse(item.gov_benifits) : [];
        if (faciities.length > 0) {
          if (faciities.includes(this.state.selectedGovFacility)) {
            arr.push(item);
          }
        }
      }
      gov_f_arr = arr;
    } else {
      gov_f_arr = taluka_arr;
    }
    let all_filtered_data = gov_f_arr;
    if (gov_f_arr.length > 0) {
      let tempArr = gov_f_arr.sort((a, b) =>
        a.type_of_disability.localeCompare(b.type_of_disability)
      );
      all_filtered_data = tempArr;
    }
    this.setState({
      records: all_filtered_data,
    });
  };
  exportToCSV = () => {
    let fileName = "Candidates";
    let arr = [];
    for (let item of this.state.records) {
      let obj = {
        "Sahaas Id": item.sahaas_id,
        Name: item.name,
        Gender: item.gender,
        Disability: item.type_of_disability ? item.type_of_disability : "",
        "Phone No": item.personal_contact ? item.personal_contact : "",
        "Adhar No": item.adhar_no ? item.adhar_no : "",
        Address: "",
      };
      arr = [...arr, obj];
    }
    const ws = XLSX.utils.json_to_sheet(arr);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    message.success("Data exported successfully.");
  };
  showExportModal = () => {
    this.setState({
      exportModalStatus: true,
    });
  };
  export_single_data = () => {
    this.setState({
      exportLoader: true,
    });
    let list = this.state.records;
    let sorted_list = list.sort((a, b) => a.name.localeCompare(b.name));
    // console.log("sorted_list", sorted_list);
    this.generate_pdf_of_single_suer_on_by_one(0, sorted_list);
  };
  export_tabular_data = () => {
    let token = localStorage.getItem("v_token");
    let sorted_list = this.state.records.sort((a, b) =>
      a.district_name.localeCompare(b.district_name)
    );
    let params = {
      list: JSON.stringify(sorted_list),
    };
    axios
      .post(URLs.generate_tabular_users_pdf + "?token=" + token, params)
      .then((response) => {
        if (response.data.status == "1") {
          const url = URLs.BASE_URL + response.data.url;
          window.open(url, "_blank");
          message.success("PDF generated Successfully!");
          this.setState({
            exportLoader: false,
            export_type: "",
            exportModalStatus: false,
          });
          return;
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        } else {
        }
      })
      .catch((error) => {});
  };
  export_data = () => {
    if (this.state.records.length == 0) {
      message.warning("Candidates not available.");
      return;
    }
    if (!this.state.export_type) {
      message.warning("Please select export type.");
      return;
    }
    if (this.state.export_type == "Tabular") {
      this.export_tabular_data();
    } else if (this.state.export_type == "Single") {
      this.export_single_data();
    }
  };
  generate_pdf_of_single_suer_on_by_one = (index, list) => {
    console.log("index", index);
    if (index == list.length) {
      message.success("All user data is exported sccuessfully.");
      this.setState({
        exportLoader: false,
        export_type: "",
        exportModalStatus: false,
      });
      return;
    } else {
      let currentObj = list[index];
      let candidate_id = currentObj.candidate_id;
      let token = localStorage.getItem("v_token");
      let params = {
        candidate_id: candidate_id,
      };
      axios
        .post(URLs.generate_single_users_pdf + "?token=" + token, params)
        .then((response) => {
          if (response.data.status == "1") {
            const url = URLs.BASE_URL + response.data.url;
            window.open(url, "_blank");
            // download(url);
            let num = Number(index + 1);
            this.setState({
              exportCount: num,
            });
            this.generate_pdf_of_single_suer_on_by_one(num, list);
            // Open the link in a new tab
            // window.open(url, "_blank");
            // console.log("response.data", response.data.url);
            // return;
          } else if (response.data.status == "2") {
            message.warning(response.data.message);
            setTimeout(() => {
              localStorage.removeItem("volunteer_id");
              localStorage.removeItem("volunteer_name");
              localStorage.removeItem("v_token");
              window.location.assign("/");
            }, 3000);
          } else {
          }
        })
        .catch((error) => {});
    }
  };

  closeModal = () => {
    this.setState({
      export_type: "",
      exportModalStatus: false,
    });
  };
  // on change table pagination
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      pagination: pagination.pageSize,
    });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        let address = "";
        if (item.state_name) {
          address += item.state_name;
        }
        if (item.district_name && item.district_name != "0") {
          address += item.state_name
            ? ", " + item.district_name
            : item.district_name;
        }
        if (item.taluka_name && item.taluka_name != "0") {
          address += item.district_name
            ? ", " + item.taluka_name
            : item.taluka_name;
        }
        return {
          key: "1",
          id: item.sahaas_id,
          name: item.name,
          gender: item.gender,
          disability: item.type_of_disability ? item.type_of_disability : "---",
          phone_no: item.personal_contact ? item.personal_contact : "---",
          adhar_no: item.adhar_no ? item.adhar_no : "---",
          address: address ? address : "---",
          action: item,
        };
      });
    const columns = [
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
      },
      {
        title: "Disability",
        dataIndex: "disability",
        key: "disability",
      },
      {
        title: "Phone No",
        dataIndex: "phone_no",
        key: "phone_no",
      },
      {
        title: "Adhar No",
        dataIndex: "adhar_no",
        key: "adhar_no",
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          const menu = (
            <Menu className="action-menu participant-action-menu">
              <Menu.Item key={`edit_details_${item.index}`}>
                <a
                  className="action-buttons"
                  href="#!"
                  onClick={() =>
                    window.location.assign(
                      "/edit-candidate?cid=" + item.candidate_id
                    )
                  }
                >
                  <i
                    className="fas fa-file-alt"
                    style={{ color: "#000", fontSize: 18 }}
                  ></i>
                  <span style={{ fontSize: 14, marginLeft: 10 }}>
                    Edit Details
                  </span>
                </a>
              </Menu.Item>
            </Menu>
          );
          return (
            <div style={{ width: "100%" }}>
              <Dropdown overlay={menu}>
                <a
                  className="ant-dropdown-link action-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <box-icon name="dots-vertical"></box-icon>
                </a>
              </Dropdown>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <div className="container-scroller">
          <Header />
          <div className="container-fluid page-body-wrapper">
            {/* <!-- partial:partials/_settings-panel.html --> */}
            <Sidebar />
            {/* <!-- partial --> */}
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="card">
                  <div className="card-body">
                    <h4
                      className="card-title"
                      style={{ fontSize: 25, padding: "19px 0 25px 0" }}
                    >
                      Total Forms - (Total: {this.state.crecords.length}
                      {this.state.searchText && (
                        <span>
                          {" "}
                          - Search Result: {this.state.records.length}
                        </span>
                      )}
                      )
                    </h4>
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <div className="row">
                            <div className="col-lg-3 ml-auto"></div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <div class="form-group">
                                <label for="exampleInputUsername1">
                                  Search
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleInputUsername1"
                                  placeholder="Search"
                                  style={{ width: "100%" }}
                                  value={this.state.searchText}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        searchText: e.target.value,
                                      },
                                      () => this.filter_data()
                                    );
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-2"></div>
                            <div className="col-lg-3">
                              {/* <div class="form-group">
                                <label for="exampleSelectGender">Gender</label>
                                <select
                                  class="form-control"
                                  id="exampleSelectGender"
                                  value={this.state.selectedGender}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        selectedGender: e.target.value,
                                      },
                                      () => this.filter_data()
                                    );
                                  }}
                                >
                                  <option value="">Select</option>
                                  <option value={"male"}>Male</option>
                                  <option value={"female"}>Female</option>
                                </select>
                              </div> */}
                            </div>
                            <div className="col-lg-3">
                              {/* <div class="form-group">
                                <label for="exampleSelectGender">
                                  Disability
                                </label>
                                <select
                                  class="form-control"
                                  id="exampleSelectGender"
                                  value={this.state.selectedDisability}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        selectedDisability: e.target.value,
                                      },
                                      () => this.filter_data()
                                    );
                                  }}
                                >
                                  <option value="">Select</option>
                                  {this.state.d_list &&
                                    this.state.d_list.map((item, index) => {
                                      return (
                                        <option value={item.name} key={index}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div> */}
                              <button
                                type="button"
                                class="btn btn-primary mr-2"
                                style={{
                                  position: "relative",
                                  top: 30,
                                  height: 47,
                                  width: 240,
                                  fontSize: 16,
                                  letterSpacing: "0.9px",
                                  fontWeight: "400",
                                  marginBottom: 50,
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                                onClick={() =>
                                  window.location.assign("/add-candidate")
                                }
                              >
                                <box-icon
                                  type="solid"
                                  name="plus-circle"
                                  color="#fff"
                                  style={{ marginRight: 8 }}
                                ></box-icon>{" "}
                                Add New Candidate
                              </button>
                            </div>
                          </div>
                          {/* <div className="row">
                            <div className="col-lg-3">
                              <div class="form-group">
                                <label for="exampleInputUsername1">
                                  District
                                </label>
                                <select
                                  class="form-control"
                                  id="exampleSelectGender"
                                  value={this.state.selectedDistrict}
                                  onChange={(event) => {
                                    this.setState(
                                      {
                                        selectedDistrict: event.target.value,
                                      },
                                      () => {
                                        console.log("taluka");
                                        this.get_taluka_list();
                                        this.filter_data();
                                      }
                                    );
                                  }}
                                >
                                  <option value="">Select</option>
                                  {this.state.district_list &&
                                    this.state.district_list.map(
                                      (item, index) => {
                                        return (
                                          <option
                                            value={item.district_id}
                                            key={index}
                                          >
                                            {item.name}
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div class="form-group">
                                <label for="exampleInputUsername1">
                                  Taluka
                                </label>
                                <select
                                  class="form-control"
                                  id="exampleSelectGender"
                                  value={this.state.selectedTaluka}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        selectedTaluka: e.target.value,
                                      },
                                      () => this.filter_data()
                                    );
                                  }}
                                >
                                  <option value="">Select</option>
                                  {this.state.taluka_list &&
                                    this.state.taluka_list.map(
                                      (item, index) => {
                                        return (
                                          <option
                                            value={item.taluka_id}
                                            key={index}
                                          >
                                            {item.name}
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div class="form-group">
                                <label for="exampleInputUsername1">
                                  Goverment Facility
                                </label>
                                <select
                                  class="form-control"
                                  id="exampleSelectGender"
                                  value={this.state.selectedGovFacility}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        selectedGovFacility: e.target.value,
                                      },
                                      () => this.filter_data()
                                    );
                                  }}
                                >
                                  <option value="">Select</option>
                                  <option value="ST pass">ST pass</option>
                                  <option value="Railway Pass">
                                    Railway Pass
                                  </option>
                                  <option value="Edu. Scholarship">
                                    Edu. Scholarship
                                  </option>
                                  <option value="Pension">Pension</option>
                                  <option value="other">other</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div class="form-group">
                                <button
                                  class="btn btn-light"
                                  style={{
                                    position: "relative",
                                    top: 30,
                                    height: 47,
                                    width: 89,
                                    fontSize: 16,
                                    letterSpacing: "0.9px",
                                    fontWeight: "400",
                                    marginRight: 8,
                                  }}
                                  type="button"
                                  onClick={() => this.clearFilter()}
                                >
                                  Clear
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-primary mr-2"
                                  style={{
                                    position: "relative",
                                    top: 30,
                                    height: 47,
                                    width: 89,
                                    fontSize: 16,
                                    letterSpacing: "0.9px",
                                    fontWeight: "400",
                                  }}
                                  onClick={() => this.showExportModal()}
                                >
                                  Export
                                </button>
                              </div>
                            </div>
                          </div> */}
                          <Table
                            dataSource={dataSource}
                            columns={columns}
                            onChange={this.handleTableChange}
                            pagination={{ pageSize: this.state.pagination }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- content-w/rapper ends --> */}
              {/* <!-- partial:partials/_footer.html --> */}
              <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                  <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Copyright © 2024 . All rights reserved.
                  </span>
                </div>
              </footer>
              {/* <!-- partial --> */}
            </div>
            {/* <!-- main-panel ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>

        <Modal
          title="Export"
          visible={this.state.exportModalStatus}
          onOk={() => this.export_data()}
          okText="Export"
          onCancel={() => this.closeModal()}
          cancelText="Close"
        >
          <Spin
            tip={`${this.state.exportCount}/${this.state.records.length}`}
            size="large"
            spinning={this.state.exportLoader}
          >
            <div className="export_option">
              <div
                onClick={() => this.setState({ export_type: "Tabular" })}
                className={
                  this.state.export_type == "Tabular"
                    ? `single active`
                    : "single"
                }
              >
                Tabular
              </div>
              <div
                onClick={() => this.setState({ export_type: "Single" })}
                className={
                  this.state.export_type == "Single"
                    ? `single active`
                    : "single"
                }
              >
                Detailed
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
