import React, { Component } from "react";
import { Checkbox, Radio, message } from "antd";
import URLs from "../../utils/apis";
import axios from "axios";

export default class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_health_problem: "",
      family_environment: "",
      medical_help: [],
      medical_help_description: "",
      guidance: [],
      guidance_description: "",
      rehabilitation: [],
      rehabilitation_description: "",
      other_help: [],
      other_help_description: "",
      artificial_instrument: [],
      artificial_instrument_description: "",
      candidate_id: "",
    };
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    // Get the value of the 'id' parameter
    const cid = searchParams.get("cid");
    if (cid) {
      this.setState(
        {
          candidate_id: cid,
        },
        () => this.get_list()
      );
    } else {
      window.location.assign("/submitted-forms");
    }
  }
  get_list = () => {
    let params = {
      volunteer_id: localStorage.getItem("volunteer_id"),
    };
    let token = localStorage.getItem("v_token");
    axios
      .post(URLs.get_candidate_list + "?token=" + token, params)
      .then((response) => {
        if (response.data.status == "1") {
          for (let item of response.data.data) {
            if (item.candidate_id == this.state.candidate_id) {
              this.setState({
                current_health_problem: item.current_health_problem,
                family_environment: item.family_environment,
                medical_help: item.medical_help
                  ? JSON.parse(item.medical_help)
                  : [],
                medical_help_description: item.medical_help_description,
                guidance: item.guidance ? JSON.parse(item.guidance) : [],
                guidance_description: item.guidance_description,
                rehabilitation: item.rehabilitation
                  ? JSON.parse(item.rehabilitation)
                  : [],
                rehabilitation_description: item.rehabilitation_description,
                other_help: item.other_help ? JSON.parse(item.other_help) : [],
                other_help_description: item.other_help_description,
                artificial_instrument: item.artificial_instrument
                  ? JSON.parse(item.artificial_instrument)
                  : [],
                artificial_instrument_description:
                  item.artificial_instrument_description,
              });
              return;
            }
          }
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  submit = () => {
    if (!this.state.candidate_id) {
      message.warning("Invalid request. Please try again.");
      return;
    }
    let params = {
      current_health_problem: this.state.current_health_problem,
      family_environment: this.state.family_environment,
      medical_help: JSON.stringify(this.state.medical_help),
      medical_help_description: this.state.medical_help_description,
      guidance: JSON.stringify(this.state.guidance),
      guidance_description: this.state.guidance_description,
      rehabilitation: JSON.stringify(this.state.rehabilitation),
      rehabilitation_description: this.state.rehabilitation_description,
      other_help: JSON.stringify(this.state.other_help),
      other_help_description: this.state.other_help_description,
      artificial_instrument: JSON.stringify(this.state.artificial_instrument),
      artificial_instrument_description:
        this.state.artificial_instrument_description,
      id: this.state.candidate_id,
      step: "6",
    };
    let token = localStorage.getItem("v_token");
    axios
      .post(URLs.update_candidate_details + "?token=" + token, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.set();
            }
          );
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("volunteer_id");
            localStorage.removeItem("volunteer_name");
            localStorage.removeItem("v_token");
            window.location.assign("/");
          }, 3000);
        } else {
          message.warning(response.data.message);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Something went wrong.Please try again.");
      });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  handleChangeCertificate = (event) => {
    this.setState({ isCertificateOfDisability: event.target.value });
  };
  onChangeRehabilitation = (e) => {
    let arr = this.state.rehabilitation;
    if (arr.includes(e.target.value)) {
      let temparr = [];
      for (let item of arr) {
        if (item != e.target.value) {
          temparr.push(item);
        }
      }
      this.setState({
        rehabilitation: temparr,
      });
    } else {
      arr.push(e.target.value);
      this.setState({
        rehabilitation: arr,
      });
    }
  };
  onChangeMedicalHelp = (e) => {
    let arr = this.state.medical_help;
    if (arr.includes(e.target.value)) {
      let temparr = [];
      for (let item of arr) {
        if (item != e.target.value) {
          temparr.push(item);
        }
      }
      this.setState({
        medical_help: temparr,
      });
    } else {
      arr.push(e.target.value);
      this.setState({
        medical_help: arr,
      });
    }
  };
  onChangeArtificial = (e) => {
    let arr = this.state.artificial_instrument;
    if (arr.includes(e.target.value)) {
      let temparr = [];
      for (let item of arr) {
        if (item != e.target.value) {
          temparr.push(item);
        }
      }
      this.setState({
        artificial_instrument: temparr,
      });
    } else {
      arr.push(e.target.value);
      this.setState({
        artificial_instrument: arr,
      });
    }
  };
  onChangeGuidance = (e) => {
    let arr = this.state.guidance;
    if (arr.includes(e.target.value)) {
      let temparr = [];
      for (let item of arr) {
        if (item != e.target.value) {
          temparr.push(item);
        }
      }
      this.setState({
        guidance: temparr,
      });
    } else {
      arr.push(e.target.value);
      this.setState({
        guidance: arr,
      });
    }
  };
  onChangeOtherhelp = (e) => {
    let arr = this.state.other_help;
    if (arr.includes(e.target.value)) {
      let temparr = [];
      for (let item of arr) {
        if (item != e.target.value) {
          temparr.push(item);
        }
      }
      this.setState({
        other_help: temparr,
      });
    } else {
      arr.push(e.target.value);
      this.setState({
        other_help: arr,
      });
    }
  };
  render() {
    return (
      <div>
        <div className="row">
          <div className="form-group col-lg-6">
            <label className="pt-2 font-weight-medium">
              Current health problem{" "}
            </label>
            <textarea
              id="txtid"
              name="txtname"
              rows="4"
              cols="50"
              maxlength="200"
              className="form-control border-1"
              value={this.state.current_health_problem}
              onChange={(e) =>
                this.setState({ current_health_problem: e.target.value })
              }
            ></textarea>
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">
              Family Environment{" "}
            </label>
            <br />
            <Radio.Group
              onChange={(e) =>
                this.setState({ family_environment: e.target.value })
              }
              value={this.state.family_environment}
            >
              <Radio value={"Good"}>Good</Radio>
              <Radio value={"Not Good"}>Not Good</Radio>
            </Radio.Group>
          </div>
          <div className="form-group col-lg-12">
            <hr style={{ marginBottom: 0, marginTop: 0 }} />
          </div>
          <div className="col-lg-12">
            <h4 className="pt-2 pb-2">Assistance Required From Saahas</h4>
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Medical Help</label>
            <div style={{ paddingTop: 15 }}>
              <Checkbox
                onChange={this.onChangeMedicalHelp}
                value="Physiotherapy"
                checked={
                  this.state.medical_help.includes("Physiotherapy")
                    ? true
                    : false
                }
              >
                Physiotherapy
              </Checkbox>
              <Checkbox
                onChange={this.onChangeMedicalHelp}
                value="Surgery"
                checked={
                  this.state.medical_help.includes("Surgery") ? true : false
                }
              >
                Surgery
              </Checkbox>
            </div>
          </div>
          <div className="form-group col-lg-6">
            <label className="pt-2 font-weight-medium">
              Medical Help Explaination
            </label>
            <textarea
              id="txtid"
              name="txtname"
              rows="4"
              cols="50"
              maxlength="200"
              className="form-control border-1"
              value={this.state.medical_help_description}
              onChange={(e) =>
                this.setState({ medical_help_description: e.target.value })
              }
            ></textarea>
          </div>
          <div className="form-group col-lg-12">
            <hr style={{ marginBottom: 0, marginTop: 0 }} />
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Rehabilitation</label>
            <div style={{ paddingTop: 15 }}>
              <Checkbox
                onChange={this.onChangeRehabilitation}
                value="Hostel Admission"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.rehabilitation.includes("Hostel Admission")
                    ? true
                    : false
                }
              >
                Hostel Admission
              </Checkbox>
              <Checkbox
                onChange={this.onChangeRehabilitation}
                value="Job"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.rehabilitation.includes("Job") ? true : false
                }
              >
                Job
              </Checkbox>
              <Checkbox
                onChange={this.onChangeRehabilitation}
                value="Business Training"
                checked={
                  this.state.rehabilitation.includes("Business Training")
                    ? true
                    : false
                }
              >
                Business Training
              </Checkbox>
              <Checkbox
                onChange={this.onChangeRehabilitation}
                value="Marriage Registration"
                checked={
                  this.state.rehabilitation.includes("Marriage Registration")
                    ? true
                    : false
                }
              >
                Marriage Registration
              </Checkbox>
              <Checkbox
                onChange={this.onChangeRehabilitation}
                value="Work From Home"
                checked={
                  this.state.rehabilitation.includes("Work From Home")
                    ? true
                    : false
                }
              >
                Work From Home
              </Checkbox>
            </div>
          </div>
          <div className="form-group col-lg-6">
            <label className="pt-2 font-weight-medium">
              Rehabilitation Explaination
            </label>
            <textarea
              id="txtid"
              name="txtname"
              rows="4"
              cols="50"
              maxlength="200"
              className="form-control border-1"
              value={this.state.rehabilitation_description}
              onChange={(e) =>
                this.setState({ rehabilitation_description: e.target.value })
              }
            ></textarea>
          </div>
          <div className="form-group col-lg-12">
            <hr style={{ marginBottom: 0, marginTop: 0 }} />
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Guidance</label>
            <div style={{ paddingTop: 15 }}>
              <Checkbox
                onChange={this.onChangeGuidance}
                value="Handicap Certificate"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.guidance.includes("Handicap Certificate")
                    ? true
                    : false
                }
              >
                Handicap Certificate
              </Checkbox>
              <Checkbox
                onChange={this.onChangeGuidance}
                value="UDID Card"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.guidance.includes("UDID Card") ? true : false
                }
              >
                UDID Card
              </Checkbox>
              <Checkbox
                onChange={this.onChangeGuidance}
                value="GHARKUL Scheme"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.guidance.includes("GHARKUL Scheme") ? true : false
                }
              >
                GHARKUL Scheme
              </Checkbox>
              <Checkbox
                onChange={this.onChangeGuidance}
                value="Business Loan"
                checked={
                  this.state.guidance.includes("Business Loan") ? true : false
                }
                style={{ marginBottom: 7 }}
              >
                Business Loan
              </Checkbox>
              <Checkbox
                onChange={this.onChangeGuidance}
                value="Pension"
                checked={this.state.guidance.includes("Pension") ? true : false}
                style={{ marginBottom: 7 }}
              >
                Pension
              </Checkbox>
            </div>
          </div>
          <div className="form-group col-lg-6">
            <label className="pt-2 font-weight-medium">
              Guidance Explaination
            </label>
            <textarea
              id="txtid"
              name="txtname"
              rows="4"
              cols="50"
              maxlength="200"
              className="form-control border-1"
              value={this.state.guidance_description}
              onChange={(e) =>
                this.setState({ guidance_description: e.target.value })
              }
            ></textarea>
          </div>
          <div className="form-group col-lg-12">
            <hr style={{ marginBottom: 0, marginTop: 0 }} />
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">
              Artificial Instrument
            </label>
            <div style={{ paddingTop: 15 }}>
              <Checkbox
                onChange={this.onChangeArtificial}
                value="Wheel Chair"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.artificial_instrument.includes("Wheel Chair")
                    ? true
                    : false
                }
              >
                Wheel Chair
              </Checkbox>
              <Checkbox
                onChange={this.onChangeArtificial}
                value="Tricycle"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.artificial_instrument.includes("Tricycle")
                    ? true
                    : false
                }
              >
                Tricycle
              </Checkbox>
              <Checkbox
                onChange={this.onChangeArtificial}
                value="Walker"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.artificial_instrument.includes("Walker")
                    ? true
                    : false
                }
              >
                Walker
              </Checkbox>
              <Checkbox
                onChange={this.onChangeArtificial}
                value="Hearing Machine"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.artificial_instrument.includes("Hearing Machine")
                    ? true
                    : false
                }
              >
                Hearing Machine
              </Checkbox>
              <Checkbox
                onChange={this.onChangeArtificial}
                value="Calliper"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.artificial_instrument.includes("Calliper")
                    ? true
                    : false
                }
              >
                Calliper
              </Checkbox>
              <Checkbox
                onChange={this.onChangeArtificial}
                value="Jaipur Foot"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.artificial_instrument.includes("Jaipur Foot")
                    ? true
                    : false
                }
              >
                Jaipur Foot
              </Checkbox>
              <Checkbox
                onChange={this.onChangeArtificial}
                value="Kubadi"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.artificial_instrument.includes("Kubadi")
                    ? true
                    : false
                }
              >
                Kubadi
              </Checkbox>
            </div>
          </div>
          <div className="form-group col-lg-6">
            <label className="pt-2 font-weight-medium">
              Artificial Instrument Explaination
            </label>
            <textarea
              id="txtid"
              name="txtname"
              rows="4"
              cols="50"
              maxlength="200"
              className="form-control border-1"
              value={this.state.artificial_instrument_description}
              onChange={(e) =>
                this.setState({
                  artificial_instrument_description: e.target.value,
                })
              }
            ></textarea>
          </div>
          <div className="form-group col-lg-12">
            <hr style={{ marginBottom: 0, marginTop: 0 }} />
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Other Help </label>
            <div style={{ paddingTop: 15 }}>
              <Checkbox
                onChange={this.onChangeOtherhelp}
                value="Financial Help"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.other_help.includes("Financial Help")
                    ? true
                    : false
                }
              >
                Financial Help
              </Checkbox>
              <Checkbox
                onChange={this.onChangeOtherhelp}
                value="Educational Help"
                style={{ marginBottom: 7 }}
                checked={
                  this.state.other_help.includes("Educational Help")
                    ? true
                    : false
                }
              >
                Educational Help
              </Checkbox>
            </div>
          </div>
          <div className="form-group col-lg-6">
            <label className="pt-2 font-weight-medium">
              Other Help Explaination
            </label>
            <textarea
              id="txtid"
              name="txtname"
              rows="4"
              cols="50"
              maxlength="200"
              className="form-control border-1"
              value={this.state.other_help_description}
              onChange={(e) =>
                this.setState({ other_help_description: e.target.value })
              }
            ></textarea>
          </div>
        </div>
        <button
          className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
          type="button"
          style={{
            width: 130,
          }}
          onClick={() => this.submit()}
        >
          Update Details
        </button>
      </div>
    );
  }
}
